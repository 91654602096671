<template>
  <b-card title="">
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right d-none">
          <button
            class="btn btn-info btn-icon mr-1"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <button
            class="btn btn-info btn-icon"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <plans-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Ajouter un client</h3>
      <plan-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Intitulé</th>
          <th class="text-center">Quantité</th>
          <th class="text-right">Prix</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="plan in plans">
          <tr :key="plan.id" @click.prevent="$router.push(`/plans/${plan.id}`)">
            <td>
              <h4>
                {{ plan.label }}
                <br />
                <small>
                  <b-badge
                    v-if="plan.scope"
                    pill
                    variant="success"
                    class="mr-1"
                  >
                    {{ plan.scope }}
                  </b-badge>
                  <b-badge
                    v-if="plan.status"
                    pill
                    variant="secondary"
                    class="mr-1"
                  >
                    {{ plan.status }}
                  </b-badge>
                  <b-badge
                    v-if="plan.highlight"
                    pill
                    variant="secondary"
                    class="mr-1"
                  >
                    Recommandé
                  </b-badge>
                  <b-badge
                    v-if="plan.display_on_front"
                    pill
                    variant="success"
                    class="mr-1"
                  >
                    Page d’accueil
                  </b-badge>
                </small>
              </h4>
              <p class="text-muted">{{ plan.baseline }}</p>
            </td>
            <td class="text-center">
              {{ plan.quantity }}
            </td>
            <td class="text-right">
              {{ plan.price | currency }}
              <br />
              <span class="text-muted">
                {{ (parseFloat(plan.price) / plan.quantity) | currency }}
              </span>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <pre>{{ plans }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue'
import PlansFilters from './PlansFilters.vue'

export default {
  components: {
    BCard,
    BBadge,
    PlansFilters,
  },
  data() {
    return {
      displayFilters: false,
      displayForm: false,
      plans: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 100,
        term: null,
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get('/subscriptions/plans/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields':
            'results{' +
            'id,scheme,scope,status,' +
            'label,baseline,price,quantity,' +
            'display_on_front,highlight' +
            '},meta',
        },
      })
      this.plans = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(plan) {
      this.displayForm = false
      this.$router.push(`/plans/${plan.id}/`)
    },
  },
}
</script>

<style></style>
